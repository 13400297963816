.testimonials {
  padding:30px;
  background-color: $rose;
  .testimonial {
    max-width: 60%;
    margin:0px auto;
    padding: 30px;
  }
  h2 {text-align: center;}
  .case-study {
    margin-bottom: 2rem;

    h3 {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }
  }
}

@media (max-width: 992px) {
  .testimonials .testimonial {max-width: 100%;}
  
}
