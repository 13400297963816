.success-stories {
  padding:30px 0px;
  

  h2 {text-align: center;}
  .case-study {
    margin-bottom: 2rem;
    display:flex;
  justify-content: space-between;
  ul {padding: 30px;}
  li {padding-bottom: 10px;}
  .study-copy {padding: 30px;}
    h3 {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }
  }
}

@media (max-width: 992px) {
  h2 {margin-bottom: 20px;}
  .case-study {display: block !important; img {max-width: 50%; height:auto; float:inline-end; padding-left: 8px; padding-bottom: 8px;}}
}