.get-started {
  text-align: center;
  padding:30px 0px;
  background-color: white;
  display: flex;
  align-items: center;


  .contact-details {
    margin-top: 2rem;

    p {
      margin: 0.5rem 0;
    }
  }
  h2 {font-size: 3rem; padding-bottom: 30px;}
  p {font-size: 18px; padding-bottom: 20px;}
}
