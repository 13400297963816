.about-us {

  section {
    display: flex;
  }

  img {
    flex: 1;
  }

  .about {
    padding: 22px;
    flex: 1;
  }

  ul {
    padding: 10px 30px 30px;

    li {
      padding-bottom: 10px;
    }
  }

  h2 {
    margin-bottom: 8px;
  }

  h3, h4 {
    margin: 8px 0;
  }

  .features {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 2rem;

    .feature {
      flex: 1 1 calc(33.333% - 2rem);
      max-width: calc(33.333% - 2rem);
      text-align: center;

      h3 {
        margin-top: 1rem;
      }

      p {
        margin-top: 0.5rem;
      }

      svg {
        font-size: 2rem;
        color: #007bff;
      }
    }
  }
}
@media (max-width: 992px) {
  .about-us section {display: block !important;}
  img {width: 100%; height: auto;}
}