// Color variables

$raisin: #1B1725;
$english: #534B62;
$rose: #A499B3;
$thistle: #D0BCD5;
$celtic: #226CE0;
$primary-color: $celtic;
$secondary-color: #2ecc71;
$text-color: $raisin;

// Font sizes
$font-size-base: 16px;
$font-size-heading: 2rem;
$font-size-heading2: 1.7rem;
$font-size-heading3: 1.3rem;


$transition-all: all 0.3s ease-in-out;
