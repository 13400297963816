.why-choose-us {
  padding-bottom: 30px;
  
  h2 {
    text-align: center; 
    padding-top: 15px; 
    padding-bottom: 40px;
  }
  .features {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 2rem;
  }

  .feature {
    flex: 1 1 calc(33.333% - 2rem);
    max-width: calc(33.333% - 2rem);
    text-align: center;

    h3 {
      margin-top: 1rem;
    }

    p {
      margin-top: 0.5rem;
    }

    svg {
      font-size: 13rem;
      color: $celtic;
    }
  }
}



// Responsive media queries
@media (max-width: 1200px) {
  
}


@media (max-width: 992px) {
  .features {
    display: block !important;
    .feature {max-width: 100% !important; padding-bottom: 30px; img {width: auto; height: auto;}}
  }
}

@media (max-width: 576px) {
  
}
