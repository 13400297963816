.our-process {
  background-color: $english;
  padding:30px 0px;
  text-align: center;
  h2, p {color:white;}
  
  .steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 2rem;
    padding-top: 30px;
  }

  .step {
    flex: 1 1 calc(33.333% - 2rem);
    max-width: calc(33.333% - 2rem);
    text-align: center;
    background-color: white;
    padding: 10px 25px 25px;

    h3 {
      margin-top: 1rem;
    }

    p {
      margin-top: 0.5rem;
      color:black;
    }
  }
}

@media (max-width: 992px) {
  .steps {display: block !important; .step {max-width: 100%;}}
  
}