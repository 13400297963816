header {
  background-color: transparent;
  padding: 20px 0;
  position: fixed;
  width: 100%;
  height: 100px;
  transition: $transition-all;

  .phone {color:white; text-decoration: none; font-size: 30px; svg {font-size: 22px;}}

  
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .logo {
      font-size: 24px;
      color: white;
  
      a {
        text-decoration: none;
        color: inherit;
      }
    }
  
    nav ul {
      list-style: none;
      display: flex;
  
      li {
        margin-right: 20px;
  
        a {
          color: white;
          text-decoration: none;
          font-size: 18px;
  
          &:hover {
            color: $celtic;
          }
        }
      }
    }
  }

  header.scrolled {
    background-color: $raisin;
    position: fixed;
    height: 60px; /* Smaller header height */
    width:100%;
    padding:0px;

    .container {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .logo {
      font-size: 18px;
    }
  
    nav ul {
      list-style: none;
      display: flex;
  
      li {
        margin-right: 20px;
  
        a {
          font-size: 16px;
        }
      }
    }
  }

// Responsive media queries
@media (max-width: 1200px) {
  
}


@media (max-width: 992px) {
  header {position: relative !important; padding: 0px; height:auto !important; .container { display:block !important; background-color: $raisin; width:100%;   }}
  .logo {max-width: 50%; height:auto !important; }
}

@media (max-width: 576px) {
  
}
  