nav {
    background-color: transparent;
    padding: 1rem;
    margin:0px auto;
  
    ul {
      list-style-type: none;
      display: flex;
  
      li {
        margin-right: 20px;
  
        a {
          color: white;
          text-decoration: none;
          font-size: $font-size-base;
          transition: $transition-all;
  
          &:hover {
            color: $secondary-color;
          }
        }
      }
    }
  }
  