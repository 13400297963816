.banner {
  background-color: $raisin;
  background-size: cover;
  background-position: center;
  height: 720px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: left;
  padding: 0 20px;

  .banner-content {

    h1 {
      font-size: 4rem;
      margin-bottom: 20px;
      max-width: 700px;
    }

    p {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
}
  // Responsive media queries
@media (max-width: 1200px) {
  
}


@media (max-width: 992px) {
  .banner {display: block !important; img {max-width: 75%; height:auto;}}
  .banner-content {padding-top: 30px; padding-bottom: 30px; }
  
}

@media (max-width: 576px) {
  
}

