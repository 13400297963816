* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html {
    scroll-behavior: smooth;
  }
  body {
    font-family: Arial, sans-serif;
    color: $text-color;
    font-size: $font-size-base;
    background-color: white;
  }
  
  h1 {
    font-size: $font-size-heading;
  }

  h2 {
    font-size: $font-size-heading2;
  }

  h3 {
    font-size: $font-size-heading3;
  }
  
  .btn {
    background-color: $celtic;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 4px;
    font-size: 18px;
    transition: $transition-all;

    &:hover {
      background-color: #27ae60;
    }
  }

  .content {
    h1 {margin-top: 30px; margin-bottom: 20px;}
    h2 {margin-top: 20px; margin-bottom: 10px;}
    h3 {margin-top: 10px; text-indent: 10px;}
    ul {padding: 10px 30px 30px;}
  }