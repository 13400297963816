.container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .page {
    padding: 40px 0;
  }
  
  #signup {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 992px) {
    #signup {display: block !important;}
  }