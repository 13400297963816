#footer {
  background-color: #222;
  color: #fff;
  padding: 40px 20px;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
    padding-bottom: 20px;

    .footer-section {
      flex: 1;
      margin: 0 20px;

      h4 {
        margin-bottom: 10px;
        font-size: 18px;
      }

      p {
        margin: 5px 0;
        font-size: 14px;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 5px;

          a {
            color: #fff;
            text-decoration: none;

            &:hover {
              color: #ddd;
            }
          }
        }
      }
    }

    .logo {
      .logo-img {
        max-width: 150px;
      }
    }
  }

  .footer-bottom {
    text-align: center;
    padding-top: 20px;
    border-top: 1px solid #444;
    width: 100%;
    p {
      font-size: 12px;
    }
  }
}
