.faq {
  padding:30px 0px;
  h2 { text-align: center; padding-bottom: 30px; }
}

.faq .question {
  margin: auto;
    margin-bottom: 2rem;
    max-width: 60%;
    
  }
  
  .faq .question h3 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
  